<template>
  <div class="flex flex-col" v-if="ready">
    <div
      class="w-full my-2"
      :class="{ 'px-0': borderless, 'px-5': !borderless }"
      v-for="(item, index) in items" :key="index"
    >
      <img
        v-if="item.type == 'image'"
        :src="item.url"
        alt=""
        class="w-auto mx-auto"
        :class="{ 'w-full': scale }"
      />
      <video
        v-if="item.type == 'video'"
        controls="controls"
        :src="item.url"
      />
      <audio
        v-if="item.type == 'audio'"
        controls>
        <source :src="item.url">
        Your browser does not support the audio element.
      </audio>
      <iframe
        v-if="item.type == 'youtube'"
        :src="item.url"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="w-full mx-auto md:h-96 sm:h-auto"
      >
      </iframe>
    </div>
    <div
      v-if="media.caption">
      <Caption>{{ media.caption }}</Caption>
    </div>
  </div>
</template>

<script>
import Caption from '@/components/Typography/Caption'
export default {
  props: {
    scale: Boolean,
    borderless: Boolean,
    block_data: {
      type: Object,
      default() {
          return {}
      },
      required: true
    }
  },

  components: {
    Caption
  },

  data () {
    return {
      items: [], 
      media: {},
      ready: false,
    }
  },

  mounted () {
    this.items = this.block_data;
    console.log(this.block_data)
    if (this.block_data.embed_url)
    {
      console.log(this.block_data)
      // this.media.src = this.block_data.embed_url;
      // const url = this.block_data.embed_url.split("/");
      // console.log(url);
      // this.media.src = `https://www.youtube.com/embed/${url.pop()}`
      // this.media.type = "youtube";
    }
    else
    {
      this.media.src = this.block_data.media;
      this.media.type = "image";
    }

    this.ready = true;
  }
}
</script>