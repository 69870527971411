<template>
    <div class="flex flex-col w-full">
        <div class="flex w-full mx-auto justify-center mb-10">
            <img
                src="@/assets/m.svg"
                class="w-10 m-5 max-w-xs"
            />
        </div>
        <div class="text-center text-base text-gray-800 dark:text-gray-200">
            {{ customer }} © {{ new Date().getFullYear() }} {{ reseller ? "--" : "" }} {{ reseller }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        customer: String,
        reseller: String
    }
}
</script>