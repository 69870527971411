<template>
  <p
    class="font-merriweather text-base sm:text-lg"
    :class="{ 'p-5': !no_padding, 'p-1': no_padding }"
  >
    <span v-if="block_data">
      {{ block_data.text }}
    </span>
    <slot v-els />
  </p>
</template>

<script>
export default {
  props: {
    no_padding: Boolean,
    block_data: {
      type: Object,
      default() {
          return {}
      }
    }
  }
}
</script>