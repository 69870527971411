<template>
  <div class="mx-auto md:container md:mx-auto md:max-width lg:px-40 lg:py-5 h-screen">
    <div class="w-full text-right">
      <router-link to="/demo">Demo</router-link>
      |
      <a href="http://cms.museo.org.uk">Login</a>
    </div>
    <div class="flex lg:flex-row flex-col h-full content-center">
      <div class="w-1/2 md:w-full text-center flex-grow flex content-center relative overflow-hidden pb-2/3 mx-auto">
        <img
          class="absolute h-full w-full object-contain p-2"
          src="@/assets/preview2.jpeg"
          alt="Museo App View"
          srcset=""
        >
      </div>
      <div class="w-1/2 md:w-full text-center flex items-center flex-row mx-auto">
        <div>
          <h1 class="font-playfair text-yellow-500 lg:text-7xl text-4xl py-10 w-full lg:leading-tight py-10">
            Discover more with Museo
          </h1>
          <p class="font-merriweather text-gray-800 text-base sm:text-lg pt-10 w-full">
            Enhance your digital offering and increase visitor engagement with Museo.
            Using easy to scan QR codes, invite your audience to discover more about
            artworks and exhibits by curating your own artefact guides.
          </p>
            <!-- Begin Mailchimp Signup Form -->
            <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css">
            <div id="mc_embed_signup">
              <form
                action="https://1714.us1.list-manage.com/subscribe/post?u=d84ae1c47e79d625e989472d2&amp;id=30b65cb29d"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                class="validate"
                target="_blank"
                novalidate
              >
                  <div id="mc_embed_signup_scroll">
                    <label for="mce-EMAIL">Register Your Interest</label>
                    <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required>
                      <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_d84ae1c47e79d625e989472d2_30b65cb29d" tabindex="-1" value=""></div>
                      <div class="clear">
                        <input
                          type="submit"
                          value="Register"
                          name="subscribe"
                          id="mc-embedded-subscribe"
                          class="button"
                        >
                      </div>
                  </div>
              </form>
            </div>

            <!--End mc_embed_signup-->
            <!-- <font-awesome-icon icon="envelope" /> -->
        </div>
      </div>
    </div>

    <Footer customer="Museo" />
  </div>
</template>

<script>
import Footer from '@/components/Global/Footer/Footer'
export default {
  components: {
    Footer
  }
}
</script>