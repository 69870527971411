<template>
  <section class="bg-white dark:bg-gray-900 h-full">
    <div v-if="loading" class="flex flex-col items-center justify-center w-full h-screen mx-auto">
      <Loader />
    </div>

    <div v-else class="flex flex-col space-y-10 mx-auto max-w-4xl">
      <ExhibitBuilder
        :exhibit_data="exhibit"
      />
    </div>
  </section>
</template>

<script>
import Loader from '@/components/Global/Loader/Loader';
import RestResource from '@/services/RestResource';
import ExhibitBuilder from '@/components/Content/Builder/Exhibit';
export default {
  components: {
    Loader,
    ExhibitBuilder
  },

  data () {
    return {
      loading: true,
      exhibit: {},
    }
  },

  created () {
    this.get_artefact(this.$route.params.id)
  },

  methods: {
    async get_artefact (id) {
      const restResouceService = new RestResource();
      const { data } = await restResouceService.GETRequest(`exhibit/${id}`);

      console.log(data)
      this.exhibit = data.data.published_data;
      this.loading = false;
    }
  }
}
</script>