<template>
  <p class="px-5 text-sm text-gray-500 museo-caption">
    <slot />
  </p>
</template>

<script>
export default {
  props: {
    caption: String
  }
}
</script>

<style scoped>
.museo-caption::before {
  content: "\25C6";
  margin-right: 0.2em;
  color: #CAA123;
}
</style>