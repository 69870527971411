<template>
  <section class="bg-white dark:bg-gray-900 h-full">
    <div v-if="loading" class="flex flex-col items-center justify-center w-full h-screen mx-auto">
      <Loader />
    </div>
    <div v-else class="flex flex-col space-y-10 mx-auto max-w-4xl">
      <Hero :block_data="hero_data"/>

      <Paragraph class="">
        Cetiosaurus (/ˌsiːtioʊˈsɔːrəs, ˌsiːʃi-/)[1] meaning 'whale lizard', from the Greek keteios/κήτειος meaning 'sea monster' (later, 'whale') and sauros/σαυρος meaning 'lizard', is a herbivorous sauropod dinosaur from the Middle Jurassic Period, living about 167 million years ago in what is now Europe.
      </Paragraph>

      <Paragraph class="">
        Cetiosaurus was in 1842 the first sauropod from which bones were described and is the most complete sauropod found in England. It was so named because its describer, Sir Richard Owen, supposed it was a marine creature, initially an extremely large crocodile, and did not recognise it for a land-dwelling dinosaur.
      </Paragraph>

      <Media
        :block_data="demo_media2"
      />

      <Paragraph class="">
        Because of the early description many species would be named in the genus, eventually eighteen of them. Most of these have now been placed in other genera or are understood to be dubious names, based on poor fossil material.
      </Paragraph>

      <Media
        :block_data="demo_media3"
      />

      <Paragraph class="">
        The last is true also of the original type species, Cetiosaurus medius, and so C. oxoniensis was officially made the new type species in 2014. C. oxoniensis is based on three more or less complete specimens, discovered from 1868 onwards. Together they contain most of the bones, with the exception of the skull.
      </Paragraph>

      <!-- <List /> -->

      <!-- Page Builder -->

      <Paragraph class="">
        
      </Paragraph>

      <Footer customer="1714 + Doc Media Centre" reseller="" />
    </div>
  </section>
</template>

<script>
import Loader from '@/components/Global/Loader/Loader'
import Hero from '@/components/Content/Hero/Hero'
import Paragraph from '@/components/Typography/Paragraph'
// import List from '@/components/Content/List/List'
import Media from '@/components/Content/Media/Media'
import Footer from '@/components/Global/Footer/Footer'
export default {
  components: {
    Loader,
    Hero,
    Paragraph,
    // List,
    Media,
    Footer
  },

  data() {
    return {
      loading: true,
      hero_data: {
        background_image: require("@/assets/demo_img1.jpeg"),
        title: "The Rutland Dinosaur",
        sub_title: ""
      },
      demo_media2: {
        type: "image",
        media: require("@/assets/demo_img2.jpeg"),
        caption: "Artists imagining of the Catiosaurus, shown here in its natural habitat during the Middle Jurassic Period."
      },
      demo_media3: {
        type: "youtube",
        embed_url: "https://www.youtube.com/embed/83ixWjcsd1M"
      }
    }
  },

  mounted() {
    setTimeout(() => {
      this.hideLoader()
    }, 3500)
  },

  methods: {
    hideLoader() {
      this.loading = false;
    }
  }
}
</script>