<template>
  <div class="dark:bg-white bg-gray-900 flex flex-col">
    <!-- Hero Image -->
    <div class="mx-auto w-full">
      <img
        v-if="block_data.background_image"
        :src="block_data.background_image"
        :alt="block_data.title"
        class="w-full h-full object-contain"
      >
    </div>

    <!-- Hero Text -->
    <div>
      <Heading class="dark:text-gray-900 text-white">
        {{ block_data.title }}
      </Heading>

      <Paragraph class="dark:text-gray-900 text-white">
        {{ block_data.sub_title }}
      </Paragraph>
    </div>
  </div>
</template>

<script>
import Heading from '@/components/Typography/Heading'
import Paragraph from '@/components/Typography/Paragraph'
export default {
  props: {
    block_data: {
      type: Object,
      default() {
          return {}
      },
      required: true
    }
  },
  components: {
    Heading,
    Paragraph
  }
}
</script>