import axios from 'axios'

const INSTANCE = axios.create({
  baseURL: process.env.VUE_APP_API_ROUTE,
  timeout: 300000
})


export default class RestResource {
//   async POSTRequest (endpoint, payload) {
//     return await INSTANCE.post(endpoint, payload)
//   }

  async GETRequest (endpoint) {
    return await INSTANCE.get(endpoint)
  }

//   async PUTRequest (endpoint, payload) {
//     return await INSTANCE.put(endpoint, payload)
//   }
//   async PATCHRequest (endpoint, payload) {
//     return await INSTANCE.patch(endpoint, payload)
//   }

//   async DELETERequest (endpoint) {
//     return await INSTANCE.delete(endpoint)
//   }
}