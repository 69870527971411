<template>
  <div>
    <div v-for="(row, row_index) in form" :key="row_index">
      <div v-for="(col, col_index, index) in row" :key="col_index">
        <component
            class="my-4"
            :is="set_component(`${row_index},c_${index + 1}`)"
            :block_data="col.data"
        />
      </div>
    </div>

    <!-- {{ exhibit_data }} -->
  </div>
</template>

<script>
import Hero from '@/components/Content/Hero/Hero'
import Paragraph from '@/components/Typography/Paragraph'
// import List from '@/components/Content/List/List'
import Media from '@/components/Content/Media/Media'

export default {
  props: {
    exhibit_data: {
      type: Object,
      default() {
          return {}
      },
      required: true
    }
  },

  data () {
    return {
      form: {}
    }
  },

  mounted () {
    this.sort_form();
  },

  methods: {
    sort_form () {
      const temp_form = {};

      for (let x = 0; x < Object.keys(this.exhibit_data.content).length; x++) {
        let key = x + 1;
        temp_form[`r_${key}`] = this.exhibit_data.content[`r_${key}`];
      }

      this.form = temp_form;
    },

    set_component (location) {
      const location_keys = location.split(',');
      const structure = this.exhibit_data.template.structure[location_keys[0]][location_keys[1]];

      switch (structure.name) {
        case 'Hero':
          return Hero;
        case 'Paragraph':
          return Paragraph;
        case 'Media':
          return Media;
      }
    },
  }
}
</script>

